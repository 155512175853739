import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";

function Login() {

    const useStylesTextField = makeStyles(theme => ({
        root: {
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderColor: "white"
            },
            "&:before": {
                borderColor: "white"
            },
            color:"white",
            borderBottomColor:"white",
            "& label.Mui-focused": {
                color: "white"
            },
            "& label": {
                color: "white"
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline:before": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline:hover": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline": {
                borderBottomColor: "white",
                color: "white"
            },
            '&:hover:not($disabled):after': {
                borderBottomColor: "white",
                color:"white"
            },
            '&:hover:not($disabled):before': {
                borderBottomColor: "white",
                color:"white"
            },
            '&:hover': {
                borderBottomColor: "white",
                color:"white"
            },
        }
    }));

    //eslint-disable-next-line
    const useStylesCheck = makeStyles({
        root: {
            padding:0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: 3,
            width: 16,
            height: 16,
            padding:0,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            padding:0,
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                loginForm()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);


    //eslint-disable-next-line
    const [UserValue, setUser] = useState('')
    //eslint-disable-next-line
    const [PasswordValue, setPassword] = useState('')
    // const [CheckBoxValue, setCheckBox] = useState(false)

    function loginForm() {
        document.getElementById("loginForm").submit();
    }

    function passwordResetRedirect() {
        window.location = "/passwordreset";
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let errQuery = params.get('err');
    let errorMessage = "";
    let displayErrorBlock = "none";

    if (errQuery) {
        errorMessage = "The username or password you entered is invalid, please try again or reset your password.";
        displayErrorBlock = "block";
    }

    // const checkclasses = useStylesCheck();
    return (
        <header className="base">
            <div className="topHeader">
            </div>
            <div className="topGap80 topGap80TextPosition">
                <div className="logoBackColor"><img alt='' className="logoBack"  src={"/images15512/InvestmentMetrics-logo-rgb_5918x488_1152dpi.png"} /></div>
                <div className="logoDisplay"><img alt='' className="logoBack1"  src={"/images15512/InvestmentMetrics-logo-small.png"} /></div>
                <div className="topGap80TextStyle"><span className=""> Insights for Success</span>
                    {/*{profileDataVal.FirstName}*/}
                </div>
            </div>
            <div className="centerBody">
                <div className="loginBox">
                    <div style={{display: displayErrorBlock}} className="error"><i className="fa fa-exclamation-triangle" aria-hidden="true"/> {errorMessage}</div>
                    <div className="loginForm">
                        <input type="hidden" id="healthz" name="healthz" value="hello"/>
                        <form id="loginForm" method="POST" action="/v1/login">
                            <div className="loginHeader">InvestmentMetrics Log In</div>

                            <input type="hidden" name="AuthMethod" value="FormsAuthentication"/>
                            <div className="textField">

                                <TextField
                                    classes={useStylesTextField()}
                                    id="userid"
                                    name="UserName"
                                    label="Email"
                                    placeholder="Email Address"
                                    onChange={event => setUser(event.target.value)}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa fa-user whiteText" aria-hidden="true"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="textField">
                                <TextField
                                    classes={useStylesTextField()}
                                    id="password"
                                    name="Password"
                                    label="Password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={event => setPassword(event.target.value)}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa fa-key" aria-hidden="true"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                        </form>
                        <div onClick={passwordResetRedirect} className="passwordResetText">
                            Reset Your Password
                        </div>
                    </div>
                    <div className="loginBoxFooter">
                        <div onClick={loginForm} className="actionButton">
                            {'Log In'}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Login;
export { default as Login } from "./login";