
import './App.css';
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Login } from "./login";
import { Home } from "./home";
import { PasswordReset } from "./passwordReset";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/login" exact component={() => <Login />} />
                </Switch>
                <Switch>
                    <Route path="/home" exact component={() => <Home />} />
                </Switch>
                <Switch>
                    <Route path="/passwordreset" exact component={() => <PasswordReset />} />
                </Switch>
            </BrowserRouter>
        </div>
    );

}

export default App;
