import React, {useEffect, useState} from "react";
import axios from 'axios';
import {Tooltip} from "@material-ui/core";

function Home() {

    // Structs
    let appAuthDefault = [
        {
            appGroups: "",
            appGuid: "",
            appIcon: "",
            appName: "",
            appSpId: "",
            appEnv: "",
            description: "",
            learnMore: "",
            learnMoreDescription: "",
            enabled: false
        }
    ];
    let appAuth = [];
    let profileDataDefault = {
        nameId: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Company: ""
    };
    // let profileData = {};
    // let announcementData = {};
    // let appAccessDefault: {
    //     appAccess: [
    //         {
    //             appGuid: "",
    //             appName: ""
    //         },
    //         {
    //             appGuid: "",
    //             appName: ""
    //         }
    //     ],
    //     time: 0,
    //     userid: ""
    // };
    let appAccessData = {
        appAccess: [],
        time: 0,
        userid: ""
    };

    // Global State Variables
    const [dataVal, setData] = useState(appAuthDefault);
    const [profileDataVal, setProfile] = useState(profileDataDefault);
    // const [accessDataVal, setAccess] = useState(appAccessDefault);
    const [profileDropDownHiddenVal, setProfileHidden] = useState('none');
    const [annDropVal, setAnnDropVal] = useState('none');
    const [annLoop, setAnnLoopData] = useState('New%20Releases');
    const [annLoopList, setAnnLoopDataList] = useState('List');
    const [activeProdBody, setActiveProdBody] = useState('prodBodyShow');
    const [activeTestBody, setActiveTestBody] = useState('testBodyHide');
    const [activeQABody, setActiveQABody] = useState('QABodyHide');
    const [activeDevBody, setActiveDevBody] = useState('DevBodyHide');
    const [activeProdTab, setActiveProdTab] = useState('prodTabsChangeColorActive');
    const [activeTestTab, setActiveTestTab] = useState('testTabsChangeColorOff');
    const [activeQATab, setActiveQATab] = useState('qaTabsChangeColorActive');
    const [activeDevTab, setActiveDevTab] = useState('devTabsChangeColorOff');
    const [prodIconsCount, setProdIconsCount] = useState(0);
    const [uatIconsCount, setUatIconsCount] = useState(0);
    const [qaIconsCount, setQAIconsCount] = useState(0);
    const [devIconsCount, setDevIconsCount] = useState(0);

    // Functions
    function submitAppForm(formname) {
        let formSubmission = formname.replace("i_", "f_")
        document.getElementById(formSubmission).submit();
    }

    //window.setTimeout(() => {
    //    window.location.reload();
    //}, 60000);

    function addProdIcons(data) {
        setProdIconsCount(data);
    }

    function addUATIcons(data) {
        setUatIconsCount(data);
    }

    function addQAIcons(data) {
        setQAIconsCount(data);
    }

    function addDevIcons(data) {
        setDevIconsCount(data);
    }

    function setTabContentTest() {
        setActiveTestBody('testBodyShow')
        setActiveTestTab('testTabsChangeColorActive')
        setActiveProdBody('prodBodyHide')
        setActiveProdTab('prodTabsChangeColorOff')
        setActiveQABody('qaBodyHide')
        setActiveQATab('qaTabsChangeColorOff')
        setActiveDevBody('devBodyHide')
        setActiveDevTab('devTabsChangeColorOff')
    }

    function setTabContentProd() {
        setActiveTestBody('testBodyHide')
        setActiveTestTab('testTabsChangeColorOff')
        setActiveProdBody('prodBodyShow')
        setActiveProdTab('prodTabsChangeColorActive')
        setActiveQABody('qaBodyHide')
        setActiveQATab('qaTabsChangeColorOff')
        setActiveDevBody('devBodyHide')
        setActiveDevTab('devTabsChangeColorOff')
    }

    function setTabContentQA() {
        setActiveTestBody('testBodyHide')
        setActiveTestTab('testTabsChangeColorOff')
        setActiveProdBody('prodBodyHide')
        setActiveProdTab('prodTabsChangeColorOff')
        setActiveQABody('qaBodyShow')
        setActiveQATab('qaTabsChangeColorActive')
        setActiveDevBody('devBodyHide')
        setActiveDevTab('devTabsChangeColorOff')
    }

    function setTabContentDev() {
        setActiveTestBody('testBodyHide')
        setActiveTestTab('testTabsChangeColorOff')
        setActiveProdBody('prodBodyHide')
        setActiveProdTab('prodTabsChangeColorOff')
        setActiveQABody('qaBodyHide')
        setActiveQATab('qaTabsChangeColorOff')
        setActiveDevBody('devBodyShow')
        setActiveDevTab('devTabsChangeColorActive')
    }

    function setProfileHiddenMethod() {
        if (profileDropDownHiddenVal === 'none') {
            setProfileHidden('block')
        }
        if (profileDropDownHiddenVal === 'block') {
            setProfileHidden('none')
        }
    }

    function setAnnouncementDrop() {
        if (annDropVal === 'none') {
            setAnnDropVal('block')
        }
        if (annDropVal === 'block') {
            setAnnDropVal('none')
        }
    }

    function learnMorePopUp(url) {
        window.open(url);
    }

    function SetBaseAppData(appDataValue) {
        setData(appDataValue);
    }

    function SetProfileData(profile) {
        setProfile(profile);
    }

    function SetAccessData(access) {
        //setAccess(access);
    }

    function logOut() {
        window.location = "/logout";
    }

    function SetAnnouncementData(data) {
        setAnnLoopData(data);
    }

    function SetAnnouncementListData(data) {
        setAnnLoopDataList(data);
    }

    // function setAnnouncement(data) {
    //     setAnnLoopData(data);
    // }

    // API Calls, All API Calls are Multi Threaded, so they will appear on the html page as the API's load, lazy load style.
    useEffect(() => {
        async function getProfileData() {
            await axios.get('/v1/getprofiledata').then((response: any) => {
                let profileDataType = {
                    nameId: response.data.nameId,
                    FirstName: response.data.FirstName,
                    LastName: response.data.LastName,
                    Email: response.data.Email,
                    Company: response.data.Company
                };
                SetProfileData(profileDataType);
            });
        }

        getProfileData()
    }, []);
    useEffect(() => {
        async function setOrAdvanceAnnouncementStep(ad) {
            await axios.get('/v1/getannouncement?file=' + ad).then((response: any) => {
                let announcementType = {
                    Announcement: atob(response.data.Announcement)
                };
                SetAnnouncementData(announcementType.Announcement);
            });
        }

        setOrAdvanceAnnouncementStep(annLoop)
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        async function setOrAdvanceAnnouncementList() {
            await axios.get('/v1/getannouncementnames').then((response: any) => {
                let announcementListType = {
                    Announcementlist: atob(response.data.Announcementlist)
                };
                SetAnnouncementListData(announcementListType.Announcementlist);
            });
        }

        setOrAdvanceAnnouncementList()
    }, []);
    useEffect(() => {
        function setIcons(appAccessData) {
            const json = JSON.stringify({filter: "authz.appAuthorization"});
            axios.post('/v1/getconfigsection', json, {
                headers: {'Content-Type': 'application/json'}
            }).then((response: any) => {
                let prod = 0;
                let uat = 0;
                let qa = 0;
                let dev = 0;
                for (let i = 0; i < response.data.length; i++) {
                    let newgroup = response.data[i].appGroups;
                    newgroup = newgroup.toString().match(/CN=(.*?),/g);
                    newgroup = newgroup.toString().replace("CN=", "").replace(",", "");
                    let enabled = false;
                    for (let c = 0; c < appAccessData.appAccess.length; c++) {
                        if (appAccessData.appAccess[c].appGuid === response.data[i].appGuid) {
                            enabled = true;
                        }
                    }
                    let appAuthType =
                        {
                            appGroups: newgroup,
                            appGuid: response.data[i].appGuid,
                            appIcon: response.data[i].appIcon,
                            appName: response.data[i].appName,
                            appSpId: response.data[i].appSpId,
                            appEnv: response.data[i].appEnv,
                            description: response.data[i].description,
                            enabled: enabled,
                            learnMore: response.data[i].learnMore,
                            learnMoreDescription: response.data[i].learnMoreDescription,
                        };
                    appAuth.push(appAuthType);
                    if (appAuthType.appEnv === 'PROD') {
                        prod++;
                        addProdIcons(prod);
                    }

                    if (appAuthType.appEnv === 'UAT') {
                        uat++;
                        addUATIcons(uat);
                    }

                    if (appAuthType.appEnv === 'QA') {
                        qa++;
                        addQAIcons(qa);
                    }

                    if (appAuthType.appEnv === 'DEV') {
                        dev++;
                        addDevIcons(dev);
                    }

                }
                if (dev > 0) {
                    setTabContentDev()
                }
                if (qa > 0) {
                    setTabContentQA()
                }
                if (uat > 0) {
                    setTabContentTest()
                }
                if (prod > 0) {
                    setTabContentProd()
                }
                SetBaseAppData(appAuth);
            });
        }

        async function getAccessData() {
            await axios.get('/v1/getauthzdata').then(response => {
                for (let i = 0; i < response.data.appAccess.length; i++) {
                    let appAccessType = {
                        appGuid: response.data.appAccess[i].appGuid,
                        appName: response.data.appAccess[i].appName
                    };
                    appAccessData.appAccess.push(appAccessType);
                }
                appAccessData.time = response.data.time;
                appAccessData.userid = response.data.userid;
                SetAccessData(appAccessData);
                setIcons(appAccessData);
            });
        }

        getAccessData();
        //eslint-disable-next-line
    }, []);

    // HTML BASE
    return (
        <header className="base">
            <div className="topHeader">
            </div>
            <div className="topGap80 topGap80TextPosition">
                <div className="logoBackColor"><img alt='' className="logoBack"
                                                    src={"/images15512/InvestmentMetrics-logo-rgb_5918x488_1152dpi.png"}/>
                </div>
                <div className="logoDisplay"><img alt='' className="logoBack1"
                                                  src={"/images15512/InvestmentMetrics-logo-small.png"}/></div>
                <div className="topGap80TextStyle"><span className=""> {profileDataVal.Email} <i
                    onClick={setProfileHiddenMethod} className="fa fa-cog fontsize30 handIcon"
                    aria-hidden="true"/></span>
                </div>
                <div style={{display: profileDropDownHiddenVal}} onClick={logOut} className="profileDropDown">
                    <div>SSO Portal Logout</div>
                </div>
            </div>
            <div className="portalPagePartition">
                <div className="portalPagePartitionSmoke">
                    <div className="portalPagePartitionMargin">
                        <div className="portalPartitionLeft">
                            <div className="portalFunctionBarLargeLeft">
                                {prodIconsCount > 0 &&
                                <div onClick={setTabContentProd} className="tabs dotPatternBackgroundTab">
                                    <div className={"infoInside tabBackgroundLight " + activeProdTab}>
                                        <div className="tabText">My Applications</div>
                                    </div>
                                </div>}
                                {uatIconsCount > 0 &&
                                <div onClick={setTabContentTest} className="tabs dotPatternBackgroundTab">
                                    <div className={"infoInside tabBackgroundDark " + activeTestTab}>
                                        <div className="tabText">My Test Applications</div>
                                    </div>
                                </div>}
                                {qaIconsCount > 0 &&
                                <div onClick={setTabContentQA} className="tabs dotPatternBackgroundTab">
                                    <div className={"infoInside tabBackgroundDark " + activeQATab}>
                                        <div className="tabText">My QA Applications</div>
                                    </div>
                                </div>}
                                {devIconsCount > 0 &&
                                <div onClick={setTabContentDev} className="tabs dotPatternBackgroundTab">
                                    <div className={"infoInside tabBackgroundDark " + activeDevTab}>
                                        <div className="tabText">My Dev Applications</div>
                                    </div>
                                </div>}
                            </div>
                            <div className="portalFunctionBodyLeft">
                                <div className="appBody backgroundINVWhitePicture">
                                    <div className="leftBody  baseShadow dotPatternBackground">
                                        <div className={"leftBodyColumn " + activeProdBody}>
                                            {dataVal.map(app => (
                                                app.enabled ?
                                                    (app.appEnv === 'PROD' &&  <div className="iconContainer"
                                                                                  onClick={() => submitAppForm("i_" + app.appGuid)}
                                                                                  id={"i_" + app.appGuid}>
                                                        <div><img alt='' className='IconStyle IconSpin'
                                                                  src={app.appIcon}/></div>
                                                        <div className="appIconUpper">{app.appName}</div>
                                                        <div className="appIconLower">{app.description}</div>
                                                        <form id={"f_" + app.appGuid} method="POST" target="_blank"
                                                              action="/adfs/ls/idpinitiatedsignon?client-request-id=2b0e94d7-491a-4da3-3e71-0080010555c6">
                                                            <input type="hidden" name="SignInOtherSite"
                                                                   value="SignInOtherSite"/>
                                                            <input type="hidden" name="RelyingParty"
                                                                   value={app.appGuid}/>
                                                            <input type="hidden" name="SignInGo" value="Sign in"/>
                                                            <input type="hidden" name="SingleSignOut"
                                                                   value="SingleSignOut"/>
                                                        </form>
                                                    </div>) : (app.appEnv === 'PROD' &&
                                                        <div className="learnMore"
                                                             onClick={() => learnMorePopUp(app.learnMore)}
                                                             id={"i_" + app.appGuid}>
                                                            <div className="learnMoreOverlay whiteText learnMoreShadow">
                                                                <Tooltip title={app.learnMoreDescription} arrow>
                                                                    <div
                                                                        className="LearnMoreEllipsis">{app.learnMoreDescription}</div>
                                                                </Tooltip>
                                                                <div className="learnMoreButton">Learn More</div>
                                                            </div>
                                                            <div><img alt='' className='IconStyleLearnMore'
                                                                      src={app.appIcon}/></div>
                                                            <div className="appIconUpperLearnMore">{app.appName}</div>
                                                            <div className="appIconLowerLearnMore">{app.description}</div>
                                                        </div>
                                                    )
                                            ))}
                                        </div>
                                        <div className={"leftBodyColumn " + activeTestBody}>
                                            {dataVal.map(app => (
                                                app.enabled ?
                                                    (app.appEnv === 'UAT' && <div className="iconContainer"
                                                                                 onClick={() => submitAppForm("i_" + app.appGuid)}
                                                                                 id={"i_" + app.appGuid}>
                                                        <div><img alt='' className='IconStyleUAT IconSpin'
                                                                  src={app.appIcon}/></div>
                                                        <div className="appIconUpper">{app.appName}</div>
                                                        <div className="appIconLower">{app.description}</div>
                                                        <form id={"f_" + app.appGuid} method="POST" target="_blank"
                                                              action="/adfs/ls/idpinitiatedsignon?client-request-id=2b0e94d7-491a-4da3-3e71-0080010555c6">
                                                            <input type="hidden" name="SignInOtherSite"
                                                                   value="SignInOtherSite"/>
                                                            <input type="hidden" name="RelyingParty"
                                                                   value={app.appGuid}/>
                                                            <input type="hidden" name="SignInGo" value="Sign in"/>
                                                            <input type="hidden" name="SingleSignOut"
                                                                   value="SingleSignOut"/>
                                                        </form>
                                                    </div>) : (app.appEnv === 'UAT' &&
                                                        <div className="learnMore"
                                                             onClick={() => learnMorePopUp(app.learnMore)}
                                                             id={"i_" + app.appGuid}>
                                                            <div className="learnMoreOverlay whiteText learnMoreShadow">
                                                                <Tooltip title={app.learnMoreDescription} arrow>
                                                                    <div
                                                                        className="LearnMoreEllipsis">{app.learnMoreDescription}</div>
                                                                </Tooltip>
                                                                <div className="learnMoreButton">Learn More</div>
                                                            </div>
                                                            <div><img alt='' className='IconStyleLearnMoreUAT'
                                                                      src={app.appIcon}/></div>
                                                            <div className="appIconUpperLearnMore">{app.appName}</div>
                                                            <div className="appIconLowerLearnMore">{app.description}</div>
                                                        </div>
                                                    )
                                            ))}
                                        </div>
                                        <div className={"leftBodyColumn " + activeQABody}>
                                            {dataVal.map(app => (
                                                app.enabled ?
                                                    (app.appEnv === 'QA' && <div className="iconContainer"
                                                                                 onClick={() => submitAppForm("i_" + app.appGuid)}
                                                                                 id={"i_" + app.appGuid}>
                                                        <div><img alt='' className='IconStyleQA IconSpin'
                                                                  src={app.appIcon}/></div>
                                                        <div className="appIconUpper">{app.appName}</div>
                                                        <div className="appIconLower">{app.description}</div>
                                                        <form id={"f_" + app.appGuid} method="POST" target="_blank"
                                                              action="/adfs/ls/idpinitiatedsignon?client-request-id=2b0e94d7-491a-4da3-3e71-0080010555c6">
                                                            <input type="hidden" name="SignInOtherSite"
                                                                   value="SignInOtherSite"/>
                                                            <input type="hidden" name="RelyingParty"
                                                                   value={app.appGuid}/>
                                                            <input type="hidden" name="SignInGo" value="Sign in"/>
                                                            <input type="hidden" name="SingleSignOut"
                                                                   value="SingleSignOut"/>
                                                        </form>
                                                    </div>) : (app.appEnv === 'QA' &&
                                                        <div className="learnMore"
                                                             onClick={() => learnMorePopUp(app.learnMore)}
                                                             id={"i_" + app.appGuid}>
                                                            <div className="learnMoreOverlay whiteText learnMoreShadow">
                                                                <Tooltip title={app.learnMoreDescription} arrow>
                                                                    <div
                                                                        className="LearnMoreEllipsis">{app.learnMoreDescription}</div>
                                                                </Tooltip>
                                                                <div className="learnMoreButton">Learn More</div>
                                                            </div>
                                                            <div><img alt='' className='IconStyleLearnMoreQA'
                                                                      src={app.appIcon}/></div>
                                                            <div className="appIconUpperLearnMore">{app.appName}</div>
                                                            <div className="appIconLowerLearnMore">{app.description}</div>
                                                        </div>
                                                    )
                                            ))}
                                        </div>
                                        <div className={"leftBodyColumn " + activeDevBody}>
                                            {dataVal.map(app => (
                                                app.enabled ?
                                                    (app.appEnv === 'DEV' && <div className="iconContainer"
                                                                                  onClick={() => submitAppForm("i_" + app.appGuid)}
                                                                                  id={"i_" + app.appGuid}>
                                                        <div><img alt='' className='IconStyleDev IconSpin'
                                                                  src={app.appIcon}/></div>
                                                        <div className="appIconUpper">{app.appName}</div>
                                                        <div className="appIconLower">{app.description}</div>
                                                        <form id={"f_" + app.appGuid} method="POST" target="_blank"
                                                              action="/adfs/ls/idpinitiatedsignon?client-request-id=2b0e94d7-491a-4da3-3e71-0080010555c6">
                                                            <input type="hidden" name="SignInOtherSite"
                                                                   value="SignInOtherSite"/>
                                                            <input type="hidden" name="RelyingParty"
                                                                   value={app.appGuid}/>
                                                            <input type="hidden" name="SignInGo" value="Sign in"/>
                                                            <input type="hidden" name="SingleSignOut"
                                                                   value="SingleSignOut"/>
                                                        </form>
                                                    </div>) : (app.appEnv === 'DEV' &&
                                                        <div className="learnMore"
                                                             onClick={() => learnMorePopUp(app.learnMore)}
                                                             id={"i_" + app.appGuid}>
                                                            <div className="learnMoreOverlay whiteText learnMoreShadow">
                                                                <Tooltip title={app.learnMoreDescription} arrow>
                                                                    <div
                                                                        className="LearnMoreEllipsis">{app.learnMoreDescription}</div>
                                                                </Tooltip>
                                                                <div className="learnMoreButton">Learn More</div>
                                                            </div>
                                                            <div><img alt='' className='IconStyleLearnMoreDev'
                                                                      src={app.appIcon}/></div>
                                                            <div className="appIconUpperLearnMore">{app.appName}</div>
                                                            <div className="appIconLowerLearnMore">{app.description}</div>
                                                        </div>
                                                    )
                                            ))}
                                        </div>
                                    </div>
                                    <div className="leftBodyFooter">
                                    </div>
                                </div>
                                <div className="height100">
                                    <div className="appBodyRight">
                                        <div className="rightBody ">
                                            <div className="noticeBarHeader backgroundBlueGraident">
                                                <div className="partitionHeaderRight">
                                                    Announcements
                                                </div>
                                            </div>
                                            <div className="noticeBarBody backgroundINVWhite baseShadow">
                                                <div className="bodyStepsRight scroller" id="step1">
                                                    <div className="insideBody">
                                                        <div dangerouslySetInnerHTML={{__html: annLoop}}/>
                                                        <div style={{display: annDropVal}} className="fileSelection">
                                                            <div dangerouslySetInnerHTML={{__html: annLoopList}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={setAnnouncementDrop}
                                                     className="noticeBarFooter backgroundINVLightGreen">
                                                    <div className="moreItems">More Announcements&nbsp;&nbsp;</div>
                                                    <div className="moreItems"><i className="fa fa-caret-down"
                                                                                  aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portalPartitionRight">
                        <div className="portalFunctionBarRight">
                            <div className="tabs">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Home;
export {default as Home} from "./home";